// extracted by mini-css-extract-plugin
export var addresses = "styles-module--addresses--S+xOu";
export var bg = "styles-module--bg--5TOeC";
export var contact = "styles-module--contact--v7GVS";
export var copyright = "styles-module--copyright--hamqF";
export var footer = "styles-module--footer--ohZ-9";
export var image = "styles-module--image--J3+2K";
export var item = "styles-module--item--T+drs";
export var logo = "styles-module--logo--vm1Bk";
export var social = "styles-module--social--TNFTZ";
export var text = "styles-module--text--5mW+N";