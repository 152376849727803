import React from 'react'
import ReactMarkdown from 'react-markdown'

const Component = ({ body }) => {
  return (
    <ReactMarkdown
      children={body}
      transformImageUri={(uri) =>
        uri.startsWith('http') ? uri : `${process.env.GATSBY_STRAPI_URL}${uri}`
      }
    />
  )
}

export default Component
