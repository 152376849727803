import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Social from 'components/Social'
import LinkWrap from 'components/LinkWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import { StaticImage } from 'gatsby-plugin-image'
import LineLink from 'components/LineLink'
import LogoSvg from '../../assets/svg/vi/logo.svg'
import * as styles from './styles.module.scss'

const Component = ({ data, locale }) => {
  const contact = data.contact.frontmatter[locale]

  let copyright = {
    en: `© Copyright DÀYA Voyage. All rights reserved. <br /><span><a href="https://lantern.digital" target="_blank">Website design.</a></span>`,
    zh: `© 大雅行旅（成都）旅游开发有限公司 版权所有 <br /><span><a href="https://www.lanterndigital.com.cn" target="_blank">网站设计</a></span>`,
  }
  copyright = copyright[locale]
  copyright += ` <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022013793号</a>`

  return (
    <footer className={styles.footer}>
      <div className='container'>
        <Inview className={`${styles.logo} fade-in up`}>
          <div className={styles.image}>
            <LinkWrap to='/'>
              <LogoSvg />
            </LinkWrap>
          </div>
        </Inview>
        <Inview className={`${styles.contact} fade-in up`}>
          <h6>
            <LineLink
              content={{
                to: contact.email,
                text: contact.email,
                linkType: 'email',
              }}
            />
          </h6>
          <h6>{contact.phone}</h6>
        </Inview>
        <div className={`grid-24 ${styles.addresses}`}>
          {contact.addresses.map((item, i) => (
            <Address content={item} key={i} />
          ))}
        </div>
      </div>

      <div className={styles.bg}>
        <div className={styles.social}>
          <Social version='dark' locale={locale} />
        </div>
        <Inview className={`${styles.image} fade-in`}>
          <StaticImage
            src='../../assets/images/horses.jpg'
            breakpoints={[1440, 1920, 2880]}
            alt=''
          />
        </Inview>
      </div>

      <Inview className={`p-smallest ${styles.copyright} fade-in`}>
        <div className='container'>
          <p dangerouslySetInnerHTML={{ __html: copyright }} />
        </div>
      </Inview>
    </footer>
  )
}

const Address = ({ content }) => {
  const { title, address } = content
  return (
    <Inview className={`${styles.item} fade-in up`}>
      <h5 className='bold'>{title}</h5>
      <div className='p-smaller'>
        <MarkdownWrap body={address} />
      </div>
    </Inview>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          contact: markdownRemark(
            frontmatter: { en: { templateKey: { eq: "general-contact" } } }
          ) {
            frontmatter {
              en {
                email
                phone
                addresses {
                  title
                  address
                }
              }
              zh {
                email
                phone
                addresses {
                  title
                  address
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Component content={content} data={data} locale={locale} />
      )}
    />
  )
}
