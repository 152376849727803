import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({ content, locale }) => {
  const { title, seo } = content || {}
  const companyName = {
    en: 'Dàya Travel',
    zh: '大雅行旅',
  }
  const defaultTitle = `${title} | ${companyName[locale]}`
  const setTitle = seo?.title || defaultTitle
  const setDescription = seo?.description || null
  const setKeywords = seo?.keywords || null

  return (
    <Helmet>
      {setTitle && <title>{setTitle}</title>}
      {setDescription && <meta name='description' content={setDescription} />}
      {setKeywords && <meta name='keywords' content={setKeywords} />}
    </Helmet>
  )
}

export default Seo
